import React from "react";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../Components/FormContainer";
import TextInput from "../Components/TextInput";
import CustomDatePicker from "../Components/DatePicker";
import Checkbox from "../Components/Checkbox";
import RadioButton from "../Components/RadioButton";
import CustomSignaturePad from "../Components/SignaturePad";
import SubmitButton from "../Components/SubmitButton";
import { docsActions, createProfileActions, chatActions } from "_actions";
import mapEmptyToNull from "_helpers/mapEmptyToNull";
import "../../assets/styles/timeOffRequest.css";
import { usPhone } from "_utils/formatter";
import {
  getCurrentUserInfo,
  selectUserDocs,
  getResourceList,
} from "_helpers/reduxSelector";
import DocStatusTag from "_components/utils/DocStatusTag";
import { withRouter } from "react-router-dom";
import { config } from "_helpers";

const DATE_FORMAT = "MM/DD/YYYY";

const defaultValues = {
  duty_officer_name: "",
  duty_officer_signature: "",
  duty_officer_signature_date: "",
  note: "",
  cash_out_pph_check: false,
  date: "",
  employee_printed_name: "",
  employee_signature_date: "",
  pph_applied: "",
  pph_available: "",
  pph_available_date: null,
  pph_balance: "",
  pph_email: "",
  pph_inquiry_check: false,
  pph_phone_call: false,
  pph_phone_text: false,
  pph_phone: "",
  pph_phone_method: "",
  request_for_time_off_check: false,
  request_sick_pay_check: false,
  sick_pay_applied: "",
  sick_pay_available: "",
  sick_pay_balance: "",
  sick_pay_date: "",
  sick_pay_dates: [""],
  superintendent_name: "",
  superintendent_signature: "",
  superintendent_signature_date: "",
  time_off_from: "",
  time_off_to: "",
  time_off_type: "",
  use_pph: "",
  with_pay_hours: "",
  disclaimer_check: false,
};

const toastConfig = {
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const resourceTypeList = {
  1: "ADMIN",
  2: "AT",
  3: "NVTS",
};

const DUTY_OFFICER = {
  NICOLE: "abouttrees.office@gmail.com",
  MIKE: "nvtreeops@gmail.com",
  HR: "hr@firestormfire.com",
};

const TimeOffRequest = ({
  isSingleDoc,
  docDetails: docDetailsProp,
  adminMode: adminModeProps,
  goBack,
  history,
  status,
}) => {
  const dispatch = useDispatch();

  const {
    // register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    shouldFocusError: true,
  });

  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const [docDetails, setDocDetails] = React.useState(docDetailsProp);
  const [adminMode, setAdminMode] = React.useState(adminModeProps);
  const [formData, setFormData] = React.useState({});
  const [formUserData, setFormUserData] = React.useState({});
  const [formOwnerData, setFormOwnerData] = React.useState({});
  const [resourceType, setResourceType] = React.useState("");
  const [docStatus, setDocStatus] = React.useState("");
  const [ownerAdminOrSup, setOwnerAdminOrSup] = React.useState("");
  const [resourceTypeID, setResourceTypeID] = React.useState("");
  const [isDenied, setIsDenied] = React.useState(false);
  const [selectLeastOneOptionError, setSelectLeastOneOptionError] =
    React.useState(false);
  const [isSuperintendentOrAdmin, setIsSuperintendentOrAdmin] = useState(false);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const userDocs = useSelector(selectUserDocs);
  const resourceList = useSelector(getResourceList);
  const signature = useSelector((state) => state?.chat.settings?.signature);

  const WatchDisclaimerCheck = watch("disclaimer_check");
  const WatchSickPayDates = watch("sick_pay_dates");
  const WatchPphInquiryCheck = watch("pph_inquiry_check");
  const WatchSickPayDate = watch("request_sick_pay_check");
  const WatchRequestTimeOffCheck = watch("request_for_time_off_check");
  const WatchEmployeeSignature = watch("employee_signature");
  const WatchTimeOffTypeCheck = watch("time_off_type");

  const watchSupervisorName = watch("superintendent_name");
  const watchSupervisorSignature = watch("superintendent_signature");
  const watchSupervisorDate = watch("superintendent_signature_date");

  const isDutyOfficerName = watch("duty_officer_name");
  const isDutyOfficerSign = watch("duty_officer_signature");
  const isDutyOfficerDate = watch("duty_officer_signature_date");

  const pph_phone = watch("pph_phone");

  const isAdminsOwnDoc = currentUserInfo?.id === formUserData?.id;

  React.useEffect(() => {
    if (!docDetails?.docs_type_id && userDocs?.length) {
      let _docDetails = userDocs?.find(
        (item) =>
          item?.slug === docDetails?.slug && item?.docs_categories_id === 3
      );
      setDocDetails({ ..._docDetails, ...docDetails });
    }
  }, [docDetails, userDocs]);

  React.useEffect(() => {
    const fetchSettings = async () => {
      await dispatch(chatActions.getConversationSettings());
    };
    fetchSettings();
  }, []);
  const handleOnSubmit = async () => {
    let values = getValues();
    values.sick_pay_dates = `${values.sick_pay_dates}`;

    if (!!pph_phone.length && pph_phone.length < 10) {
      return toast.error("Invalid phone number format!");
    }

    // Least on Checkbox
    let validateMandatoryCheckbox = [
      "pph_inquiry_check",
      "cash_out_pph_check",
      "request_sick_pay_check",
      "request_for_time_off_check",
    ]?.map((key) => !!values?.[key]);
    //
    if (!validateMandatoryCheckbox?.includes(true)) {
      setSelectLeastOneOptionError(true);
      return;
    }
    setIsFormLoading(true);
    // Remove dependent fields if it's not checked
    if (!values?.pph_inquiry_check) {
      values.pph_email = null;
      values.pph_phone = null;
      values.pph_phone_call = null;
      values.pph_phone_text = null;
    }
    values.is_denied = isDenied ? 1 : 0;
    if (!values?.request_sick_pay_check) {
      values.sick_pay_dates = "";
    }
    if (!values?.request_for_time_off_check) {
      values.time_off_from = null;
      values.time_off_to = null;
    }
    values.is_duty_officer =
      values?.duty_officer_name && currentUserRoles?.includes("Staff Manager")
        ? 1
        : 0;
    // End
    values = mapEmptyToNull(values);
    const payload = {
      doc_type: docDetails?.docs_type_id,
      form: values,
      id: docDetails?.id,
      rehire: false,
      single_doc: true,
    };
    dispatch(docsActions.saveDoc(payload))
      .then((res) => {
        if (res?.success) {
          toast.success(
            isDenied
              ? "Time off request denied successfully."
              : "Time off request submitted!",
            {
              position: "top-right",
              autoClose: 1500,
              ...toastConfig,
            }
          );

          if (isSuperintendentOrAdmin) {
            if (docDetails.hash) {
              dispatch(docsActions.getUserDocs(docDetails.hash));
              history.push(`/profile/${docDetails.hash}`);
            } else {
              const url = history.location.pathname;
              const id = url.split("/")[2];
              dispatch(docsActions.getUserDocs(id));
              goBack();
              // history.push(`/resource-profile/${id}`)
            }
          } else {
            dispatch(docsActions.getUserDocs(formUserData.hash));
            history.push(`/settings`);
          }
          if (res?.message === "-Time off request is approved!") {
            const pdfUrl =
              config.apiUrl +
              "/docs/pdf/" +
              docDetails?.slug +
              "?id=" +
              docDetails?.id +
              "&signature=" +
              signature +
              "&uid=" +
              currentUserInfo?.hash;

            // Fetch the PDF content
            fetch(pdfUrl)
              .then((response) => response.blob())
              .then((blob) => {
                // Create a Blob URL for the PDF content
                const blobUrl = URL.createObjectURL(blob);

                // Generate a unique filename
                const timestamp = new Date().getTime(); // Current timestamp
                const filename = `Time_Off_Request_${timestamp}_${currentUserInfo.hash}.pdf`;

                // Create an anchor element
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = filename;

                // Programmatically click on the anchor element to trigger the download
                link.click();

                // Revoke the Blob URL after download
                URL.revokeObjectURL(blobUrl);
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          }
        } else {
          throw res;
        }
        setIsDenied(false);
      })
      .catch((err) => {
        setIsFormLoading(false);
        toast.error(err?.message ?? "Submission Failed", {
          position: "top-right",
          autoClose: 5000,
          ...toastConfig,
        });
      });
  };

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      //after history has pushed the url in line number 194 or 97, go to listings page
      goBack();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  React.useEffect(() => {
    const docId = docDetails?.id;
    if (docId) {
      setIsFormLoading(true);
      dispatch(docsActions.getForm(docId))
        .then((res) => {
          if (res.success) {
            let initialData = res?.data?.form;
            let userData = res?.data?.user;

            if (initialData?.sick_pay_dates) {
              initialData.sick_pay_dates = initialData?.sick_pay_dates
                ?.replaceAll(",", " ")
                .split(" ");
            }
            if (!initialData?.employee_printed_name) {
              initialData.employee_printed_name = userData?.full_name ?? "";
            }

            setFormUserData(userData);
            setFormData(initialData);
            setFormOwnerData(res?.data?.form_owner);
            setDocStatus(res?.data?.status);
          }
          setIsFormLoading(false);
        })
        .catch((err) => {
          setIsFormLoading(false);
          toast.error(err?.message ?? "Error on loading data!", {
            position: "top-right",
            autoClose: 5000,
            ...toastConfig,
          });
        });
    }
  }, []);

  React.useEffect(() => {
    dispatch(createProfileActions.getAllResource());
  }, [dispatch]);

  React.useEffect(() => {
    if (resourceList.length > 0) {
      const matchingItem = resourceList.find(
        (item) => item.id === formOwnerData?.crew_identifier_id
      );

      if (matchingItem) {
        setResourceTypeID(matchingItem.resource_type_id);
        setResourceType(resourceTypeList?.[matchingItem.resource_type_id]);
      }
    }
  }, [resourceList, formOwnerData]);

  const handleRequestForSickPayDelete = (itemIndex) => {
    let _WatchSickPayDates = [...WatchSickPayDates];
    _WatchSickPayDates.splice(itemIndex, 1);
    setValue("sick_pay_dates", _WatchSickPayDates);
  };
  const datePickerNullSanitize = (date) =>
    date ? moment(date).format(DATE_FORMAT) : "";

  React.useEffect(() => {
    if (WatchSickPayDate && !WatchSickPayDates) {
      setValue(`sick_pay_dates.0`, "");
    }
  }, [WatchSickPayDate]);

  React.useEffect(() => {
    reset(formData);
  }, [formData]);

  const currentUserRoles = React.useMemo(() => {
    return currentUserInfo?.roles?.map((item) => item?.name);
  }, [currentUserInfo]);

  const formOwnerRoles = React.useMemo(() => {
    return formOwnerData?.roles?.map((item) => item?.name);
  }, [formOwnerData]);

  const formBelongsToCurrentUser = React.useMemo(() => {
    return currentUserInfo?.hash === formUserData?.hash;
  }, [currentUserInfo, formUserData]);

  const renderDeniedStatus = () => {
    if (isSuperintendentOrAdmin && formData?.is_denied === "1") {
      return <p style={{ color: "red" }}>This document is already denied.</p>;
    } else if (!isSuperintendentOrAdmin && formData?.is_denied === "1") {
      return (
        <p style={{ color: "red" }}>
          This document is not approved ,please contact the admin for more
          details.
        </p>
      );
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    setAdminMode(
      currentUserRoles?.includes("Super Admin") ||
        currentUserRoles?.includes("Admin")
    );
  }, [currentUserRoles]);

  React.useEffect(() => {
    setOwnerAdminOrSup(
      formOwnerRoles?.includes("Super Admin") ||
        formOwnerRoles?.includes("Admin") ||
        formOwnerRoles?.includes("Crew Lead")
    );
  }, [formOwnerRoles]);

  const handleDeny = () => {
    setIsDenied(true);
  };

  // const isSuperintendentOrAdmin = currentUserRoles?.includes("Crew Lead") ||
  // currentUserRoles?.includes("Operations Manager") ||
  // currentUserRoles?.includes("Division Manager") ||
  // adminMode;

  useEffect(() => {
    setIsSuperintendentOrAdmin(
      currentUserRoles?.includes("Crew Lead") ||
        currentUserRoles?.includes("Operations Manager") ||
        currentUserRoles?.includes("Division Manager") ||
        adminMode
    );
  }, [currentUserRoles, adminMode]);

  // const disableDutyOfficerFields = hasPermissionToEditStaffOrAdmin ? (WatchSuperVisorSignature && WatchEmployeeSignature) : true
  const disableDutyOfficerFields =
    !formData.superintendent_signature && adminMode;

  //if admin login, if emp signature then no modify employees data
  const adminLoginAndEmployeeSigned =
    currentUserInfo?.is_admin > 0 && formData?.employee_signature;

  //if employee login, if sup sign /duty-off sign , sheet full disabled
  const employeeLoginAndSupSigned =
    formData.superintendent_signature && !isSuperintendentOrAdmin;

  let docStatusName = "";
  let isApproved = docStatus == 3;

  const notEditable =
    formData?.employee_signature || isApproved || formData?.is_denied === "1";

  const employeeSigned = formData?.employee_signature;

  const docStatusColor = isApproved ? "#3f7e39" : "#c9c188";

  const handleCheckboxChange = (selectedCheckbox) => {
    const checkboxFields = [
      'request_for_time_off_check',
      'request_sick_pay_check',
      'pph_inquiry_check',
      'cash_out_pph_check',
    ];
    checkboxFields.forEach((field) => {
      if (field === selectedCheckbox) {
        setValue(field, true);
      } else {
        setValue(field, false);
      }
    });
    setSelectLeastOneOptionError(false);
  };

  switch (docStatus) {
    case 1:
      docStatusName = "Not filled";
      break;
    case 2:
      docStatusName = "Submitted";
      break;
    case 3:
      docStatusName = "Approved";
      break;
   case 4:
      docStatusName = "Denied";
      break;  
    default:
      docStatusName = "Not Filled"; // Handle any other value
  }

  return (
    <FormContainer title="TIME OFF REQUEST" isLoading={isFormLoading}>
      <div className="TOR_FORM_status_tag">
        <DocStatusTag
          status={docStatusName}
          bgColor={docStatusColor}
          fontColor={isApproved ? "#fff" : ""}
        />
      </div>

      {renderDeniedStatus()}
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Row className="form-row">
          {selectLeastOneOptionError && (
            <Col xs={12}>
              <div style={{ padding: "10px 0px" }}>
                <p style={{ margin: 0, color: "red" }}>
                  {" "}
                  Employee has to select at least one (or more) option
                </p>
              </div>
            </Col>
          )}
          <Col xs={12} md={3}>
            <Controller
              name="request_for_time_off_check"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="REQUEST FOR TIME OFF"
                  value={value}
                  checked={Boolean(value)}
                  onChange={(event) => handleCheckboxChange("request_for_time_off_check")}
                  placeholder="- Enter here -"
                  disabled={employeeSigned}
                />
              )}
            />
          </Col>
          <Col xs={12} md={3}>
            <Controller
              name="request_sick_pay_check"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    label="REQUEST FOR SICK PAY"
                    checked={Boolean(value)}
                    onChange={() => handleCheckboxChange('request_sick_pay_check')}
                    placeholder="- Enter here -"
                    disabled={employeeSigned}
                  />
                );
              }}
            />
          </Col>

          <Col xs={12} md={3}>
            <Controller
              name="pph_inquiry_check"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="PPH INQUIRY"
                  checked={Boolean(value)}
                  onChange={() => handleCheckboxChange('pph_inquiry_check')}
                  placeholder="- Enter here -"
                  disabled={employeeSigned}
                />
              )}
            />
          </Col>
          <Col xs={12} md={3}>
            <Controller
              name="cash_out_pph_check"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="CASH OUT PPH"
                  checked={Boolean(value)}
                  onChange={() => handleCheckboxChange('cash_out_pph_check')}
                  placeholder="- Enter here -"
                  disabled={employeeSigned}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="form-row">
          <Col sm={12} md={8}>
            <Controller
              rules={{ required: true }}
              name="employee_printed_name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  label="Employee Name"
                  type="text"
                  value={value}
                  onChange={(event) => onChange(event?.target?.value)}
                  error={errors.employee_printed_name}
                  placeholder="- Enter here -"
                  disabled={notEditable}
                  required
                />
              )}
            />
          </Col>
        </Row>

        <Row className="form-row">
          <Col sm={12}></Col>

          <Col sm={12}>
            <div className="form-main-group">
              {WatchPphInquiryCheck && (
                <div className="form-sub-group">
                  <Row>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <label className="control-label">PPH INQUIRY:</label>
                    </Col>
                    <Col sm={12}>
                      <Controller
                        rules={{ required: WatchPphInquiryCheck }}
                        name="pph_email"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextInput
                            label="Email info to"
                            type="email"
                            value={value}
                            onChange={(event) => onChange(event?.target?.value)}
                            error={errors.pph_email}
                            placeholder="- Enter here -"
                            required={WatchPphInquiryCheck}
                            disabled={notEditable}
                          />
                        )}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={8}>
                      <Controller
                        rules={{ required: WatchPphInquiryCheck }}
                        name="pph_phone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextInput
                            label="Phone"
                            type="text"
                            value={usPhone(value)}
                            onChange={(event) => onChange(event?.target?.value)}
                            error={errors.pph_phone}
                            placeholder="- Enter here -"
                            required={WatchPphInquiryCheck}
                            disabled={notEditable}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="form-group-checkbox">
                        <Controller
                          name="pph_phone_call"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Checkbox
                              label="Call"
                              checked={Boolean(value)}
                              onChange={(event) =>
                                onChange(event?.target?.checked)
                              }
                              //   className={errors.employee_printed_name ? "error" : ""}
                              placeholder="- Enter here -"
                              disabled={notEditable}
                            />
                          )}
                        />

                        <Controller
                          name="pph_phone_text"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Checkbox
                              label="Text"
                              checked={Boolean(value)}
                              onChange={(event) =>
                                onChange(event?.target?.checked)
                              }
                              //   className={errors.employee_printed_name ? "error" : ""}
                              placeholder="- Enter here -"
                              disabled={notEditable}
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <div className="form-sub-group">
                <Row>
                  {WatchSickPayDate && (
                    <Col sm={6}>
                      <label className="control-label">
                        REQUEST FOR SICK PAY: Date(s)
                      </label>
                      {WatchSickPayDates &&
                        WatchSickPayDates.map((item, index) => (
                          <Controller
                            rules={{ required: WatchSickPayDate }}
                            key={index}
                            name={`sick_pay_dates.${index}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <div className="datePickerMain">
                                <CustomDatePicker
                                  selected={value ? moment(value) : ""}
                                  onChange={(newDate) => {
                                    const _date =
                                      datePickerNullSanitize(newDate);
                                    if (WatchSickPayDates?.includes(_date)) {
                                      onChange("");
                                    } else {
                                      onChange(datePickerNullSanitize(newDate));
                                    }
                                  }}
                                  error={errors.sick_pay_dates?.[index]}
                                  required
                                  disabled={notEditable}
                                />
                                <button
                                  type="button"
                                  className="primary"
                                  onClick={() =>
                                    handleRequestForSickPayDelete(index)
                                  }
                                  disabled={notEditable}
                                >
                                  Close
                                </button>
                              </div>
                            )}
                          />
                        ))}
                      <button
                        type="button"
                        className="button button-add-more"
                        onClick={() =>
                          setValue(
                            `sick_pay_dates.${WatchSickPayDates.length}`,
                            ""
                          )
                        }
                        disabled={notEditable}
                      >
                        Add More
                      </button>
                    </Col>
                  )}
                </Row>
                {WatchSickPayDate && (
                  <>
                    <Row>
                      <Col sm={6}>
                        <div className="list-block">
                          <ul>
                            <li>
                              <p>Available after 90 days employment</p>
                            </li>
                            <li>
                              <p>
                                Must be scheduled to work on the day requested
                              </p>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <p>If sick pay is unavailable use PPH:</p>
                      <div className="form-group-radio">
                        <Controller
                          name="use_pph"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <>
                              <RadioButton
                                checked={value === "yes"}
                                label="Yes"
                                value="yes"
                                name="use_pph"
                                onChange={(event) =>
                                  onChange(event.target.value)
                                }
                                disabled={notEditable}
                              />
                              <RadioButton
                                checked={value === "no"}
                                label="No"
                                value="no"
                                name="use_pph"
                                onChange={(event) =>
                                  onChange(event.target.value)
                                }
                                disabled={notEditable}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {WatchRequestTimeOffCheck && (
                <div className="form-sub-group">
                  <label className="control-label">REQUEST FOR TIME OFF:</label>
                  <Controller
                    rules={{ required: WatchRequestTimeOffCheck }}
                    name="time_off_from"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="From"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                        error={errors.time_off_from}
                        required={WatchRequestTimeOffCheck}
                        disabled={notEditable}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: WatchRequestTimeOffCheck }}
                    name="time_off_to"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="To"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                        error={errors.time_off_to}
                        required={WatchRequestTimeOffCheck}
                        disabled={notEditable}
                      />
                    )}
                  />

                  <div className="form-group-checkbox">
                    <Controller
                      name="time_off_type"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <RadioButton
                            checked={value === "with"}
                            label="WITH PAY:"
                            value="with"
                            name="time_off_type"
                            onChange={(event) => onChange(event.target.value)}
                            disabled={notEditable}
                          />
                          <Controller
                            name="with_pay_hours"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                label="How many hours?"
                                type="number"
                                value={value}
                                onChange={(event) =>
                                  onChange(event?.target?.value)
                                }
                                error={errors.with_pay_hours}
                                required={WatchTimeOffTypeCheck === "with"}
                                disabled={notEditable}
                              />
                            )}
                          />
                          <RadioButton
                            checked={value === "without"}
                            label="WITHOUT PAY"
                            value="without"
                            name="time_off_type"
                            onChange={(event) => onChange(event.target.value)}
                            disabled={notEditable}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <ul>
                      <li>
                        <p>
                          More than 5 days off requires one month advance
                          notice.
                        </p>
                      </li>
                      <li>
                        <p>Less then 5 days requires 2 weeks advance notice.</p>
                      </li>
                      <li>
                        <p>
                          If you do not notify your supervisor within the
                          timeframe above, you will not be paid for your leave
                          and you may jeopardize your employment.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <div
          className="signatureMainWrap"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="signatureInputWrap" style={{ width: "80%" }}>
            <div>
              <label className="control-label clearfix">
                EMPLOYEE SIGNATURE:
                {!formBelongsToCurrentUser ? null : (
                  <span class="required-star">*</span>
                )}
              </label>
            </div>

            <Controller
              name="employee_signature"
              rules={{ required: formBelongsToCurrentUser }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomSignaturePad
                  disabled={employeeSigned}
                  error={errors.employee_signature}
                  value={value}
                  onSave={(signature) => {
                    onChange(signature);
                  }}
                />
              )}
            />
          </div>
          <div className="dateInputWrap">
            <Controller
              rules={{ required: true }}
              name="date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomDatePicker
                  label="Date"
                  selected={value ? moment(value) : ""}
                  onChange={(newDate) => {
                    onChange(datePickerNullSanitize(newDate));
                  }}
                  error={errors.date}
                  required
                  disabled={notEditable}
                />
              )}
            />
          </div>
        </div>
        {!!formData.employee_signature &&
          isSuperintendentOrAdmin &&
          (resourceType === resourceTypeList[2] ||
            resourceType === resourceTypeList[3]) &&
          !ownerAdminOrSup && (
            <>
              <div>
                <p>
                  Proper notice has been given and time off has been approved
                  by:
                </p>
              </div>
              <Controller
                name="superintendent_name"
                rules={{
                  required: isSuperintendentOrAdmin
                    ? watchSupervisorName?.length === 0
                    : false,
                }}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="SUPERVISOR NAME"
                    type="text"
                    value={value}
                    disabled={
                      !isSuperintendentOrAdmin ||
                      employeeLoginAndSupSigned ||
                      formData.superintendent_signature ||
                      isAdminsOwnDoc
                    }
                    required
                    error={errors.superintendent_name}
                    onChange={(event) => onChange(event?.target?.value)}
                  />
                )}
              />
              <Row>
                <Col md={6} sm={12}>
                  <Controller
                    rules={{
                      required: isSuperintendentOrAdmin
                        ? watchSupervisorSignature?.length === 0
                        : false,
                    }}
                    name="superintendent_signature"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomSignaturePad
                        error={errors.superintendent_signature}
                        disabled={
                          !isSuperintendentOrAdmin ||
                          employeeLoginAndSupSigned ||
                          formData.superintendent_signature ||
                          isAdminsOwnDoc
                        }
                        value={value}
                        onSave={(signature) => onChange(signature)}
                      />
                    )}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Controller
                    rules={{
                      required: isSuperintendentOrAdmin
                        ? watchSupervisorDate?.length === 0
                        : false,
                    }}
                    name="superintendent_signature_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="Date"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                        error={errors.superintendent_signature_date}
                        required={!adminMode && isSuperintendentOrAdmin}
                        disabled={
                          !isSuperintendentOrAdmin ||
                          employeeLoginAndSupSigned ||
                          formData.superintendent_signature ||
                          isAdminsOwnDoc
                        }
                        minDate={moment()}
                      />
                    )}
                  />
                </Col>
              </Row>
            </>
          )}
        {((!!formData.superintendent_signature && isSuperintendentOrAdmin) ||
          resourceType === resourceTypeList[1] ||
          ownerAdminOrSup) &&
          !!formData.employee_signature && (
            <div>
              {/* Duty Offices */}
              <Controller
                rules={{
                  required: isSuperintendentOrAdmin
                    ? isDutyOfficerName?.length === 0
                    : false,
                }}
                name="duty_officer_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="DUTY OFFICER"
                    type="text"
                    value={value}
                    error={errors.duty_officer_name}
                    onChange={(event) => onChange(event?.target?.value)}
                    required
                    disabled={
                      (disableDutyOfficerFields &&
                        (resourceType === resourceTypeList[2] ||
                          resourceType === resourceTypeList[3]) &&
                        !ownerAdminOrSup) ||
                      employeeLoginAndSupSigned ||
                      formData.duty_officer_signature ||
                      isAdminsOwnDoc ||
                      (resourceType === resourceTypeList[2] &&
                        !ownerAdminOrSup &&
                        currentUserInfo.email !== DUTY_OFFICER?.NICOLE) ||
                      (resourceType === resourceTypeList[3] &&
                        !ownerAdminOrSup &&
                        currentUserInfo.email !== DUTY_OFFICER?.MIKE) ||
                      ((resourceType === resourceTypeList[1] ||
                        ownerAdminOrSup) &&
                        currentUserInfo.email !== DUTY_OFFICER?.HR)
                    }
                  />
                )}
              />
              <Row>
                <Col md={6} sm={12}>
                  <Controller
                    rules={{
                      required: isSuperintendentOrAdmin
                        ? isDutyOfficerSign?.length === 0
                        : false,
                    }}
                    name="duty_officer_signature"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomSignaturePad
                        error={errors.duty_officer_signature}
                        disabled={
                          (disableDutyOfficerFields &&
                            (resourceType === resourceTypeList[2] ||
                              resourceType === resourceTypeList[3]) &&
                            !ownerAdminOrSup) ||
                          employeeLoginAndSupSigned ||
                          formData.duty_officer_signature ||
                          isAdminsOwnDoc ||
                          (resourceType === resourceTypeList[2] &&
                            !ownerAdminOrSup &&
                            currentUserInfo.email !== DUTY_OFFICER?.NICOLE) ||
                          (resourceType === resourceTypeList[3] &&
                            !ownerAdminOrSup &&
                            currentUserInfo.email !== DUTY_OFFICER?.MIKE) ||
                          ((resourceType === resourceTypeList[1] ||
                            ownerAdminOrSup) &&
                            currentUserInfo.email !== DUTY_OFFICER?.HR)
                        }
                        value={value}
                        onSave={(signature) => onChange(signature)}
                      />
                    )}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Controller
                    rules={{
                      required: isSuperintendentOrAdmin
                        ? isDutyOfficerDate?.length === 0
                        : false,
                    }}
                    name="duty_officer_signature_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="Date"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                        error={errors.duty_officer_signature_date}
                        required={!adminMode && !disableDutyOfficerFields}
                        disabled={
                          (disableDutyOfficerFields &&
                            (resourceType === resourceTypeList[2] ||
                              resourceType === resourceTypeList[3]) &&
                            !ownerAdminOrSup) ||
                          employeeLoginAndSupSigned ||
                          formData.duty_officer_signature ||
                          isAdminsOwnDoc ||
                          (resourceType === resourceTypeList[2] &&
                            !ownerAdminOrSup &&
                            currentUserInfo.email !== DUTY_OFFICER?.NICOLE) ||
                          (resourceType === resourceTypeList[3] &&
                            !ownerAdminOrSup &&
                            currentUserInfo.email !== DUTY_OFFICER?.MIKE) ||
                          ((resourceType === resourceTypeList[1] ||
                            ownerAdminOrSup) &&
                            currentUserInfo.email !== DUTY_OFFICER?.HR)
                        }
                        minDate={moment()}
                      />
                    )}
                  />
                </Col>
              </Row>
              <label>Notes</label>
              <Controller
                control={control}
                name="note"
                render={({ field: { value, onChange } }) => (
                  <textarea
                    type="text"
                    value={value}
                    error={errors.note}
                    disabled={
                      formBelongsToCurrentUser ||
                      employeeLoginAndSupSigned ||
                      formData.duty_officer_signature ||
                      (resourceType === resourceTypeList[2] &&
                        !ownerAdminOrSup &&
                        currentUserInfo.email !== DUTY_OFFICER?.NICOLE) ||
                      (resourceType === resourceTypeList[3] &&
                        !ownerAdminOrSup &&
                        currentUserInfo.email !== DUTY_OFFICER?.MIKE) ||
                      ((resourceType === resourceTypeList[1] ||
                        ownerAdminOrSup) &&
                        currentUserInfo.email !== DUTY_OFFICER?.HR)
                    }
                    onChange={(event) => onChange(event?.target?.value)}
                  />
                )}
              />
            </div>
          )}
        {(isSuperintendentOrAdmin && !isAdminsOwnDoc) || isApproved ? (
          <>
            <div className="officeBlock">
              <p className="officeBlock__title">
                PLEASE RETURN FORM TO THE PAYROLL OFFICE (fwfspayroll@gmail.com)
              </p>

              <Row>
                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="pph_available"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        label="Available PPH:"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="pph_available_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        // minDate={moment()}
                        label="Available PPH: as of"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="pph_applied"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        label="PPH Applied:"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="pph_balance"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        label="PPH Balance:"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="sick_pay_available"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        label="Available Sick Pay:"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="sick_pay_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="Available Sick Pay: as of"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="sick_pay_applied"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        label="Sick Pay Applied:"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Controller
                    name="sick_pay_balance"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        label="Sick Pay Balance:"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        disabled={isApproved}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          </>
        ) : null}
        {!isApproved && (
          <>
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{" "}
                  <a
                    href="https://firestormfire.com/terms-of-use/"
                    target="blank"
                  >
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            <div className="disclaimer_checkbox">
              <Controller
                name="disclaimer_check"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    label=" I have confirmed the above details are true and valid."
                    value={value}
                    checked={Boolean(value)}
                    onChange={(event) => onChange(event?.target?.checked)}
                    placeholder="- Enter here -"
                    disabled={
                      formData?.is_denied === "1" ||
                      isApproved ||
                      (!isSuperintendentOrAdmin && formData?.employee_signature)
                    }
                  />
                )}
              />
            </div>
            <div className="submit_button_wrapper">
              {isSuperintendentOrAdmin && !isAdminsOwnDoc && (
                <button
                  disabled={
                    !WatchDisclaimerCheck ||
                    employeeLoginAndSupSigned ||
                    formData?.is_denied === "1"
                  }
                  className={!WatchDisclaimerCheck ? "no-disclaimer" : ""}
                  onClick={handleDeny}
                >
                  Deny
                </button>
              )}
              <SubmitButton
                disabled={
                  !WatchDisclaimerCheck ||
                  employeeLoginAndSupSigned ||
                  formData?.is_denied === "1"
                }
                type="submit"
                label={
                  isSuperintendentOrAdmin && !isAdminsOwnDoc
                    ? "Approve"
                    : "Submit Document"
                }
              />
            </div>
          </>
        )}
      </form>
    </FormContainer>
  );
};

export default withRouter(TimeOffRequest);

TimeOffRequest.propTypes = {
  isSingleDoc: PropTypes.bool.isRequired,
  docDetails: PropTypes.object,
  adminMode: PropTypes.bool.isRequired,
};
